import axios from "axios";
import { parseResponse, parseError } from '../utils/parseResponse';

export const getShowerMeetingDetails = async (meeting_Id) => {
    return axios({
        url: `${process.env.REACT_APP_API_URL}/?cmd=video.getShowerMeetingDetails`,
        method: 'post',
        params: {
            meetingId: meeting_Id,
        },
    })
        .then(parseResponse)
        .catch(parseError);
}
