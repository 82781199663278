
/**
 * Parse the response from axios requests.  Validates the response
 * and returns the result if valid.  If the request is not 'OK' then
 * an error response will be returned.
 * 
 * @param {Object} result
 * @return {Object} 
 */
export const parseResponse = (result) => {

    // _parsed flag prevents this function from being executed
    // multiple times. (i.e. passing through hook and service)
    if (result && result._parsed) {
        return result;
    }
    
    const { status } = result;
    if (status >= 200 && status < 300) {
        result._parsed = true;
        return result;
    }

    return {
        _parsed: true,
        status: 400,
        statusText: "Bad Request",
        data: {
            message: 'Oh crap. Something went wrong.'
        }
    }
}

/**
 * Parse the error of an axios request. Validates a correct
 * error response was recieved. Logs the message in to console.
 * A generic response will be returned if a server error was
 * recieved.
 * 
 * Returns an object that matches axios `error.response`.
 * 
 * @param {Object} error
 * @return {Object}
 */
export const parseError = (error) => {

    //console.log("API ERROR", error);

    // _parsed flag prevents this function from being executed
    // multiple times. (i.e. passing through hook and service)
    if (error && error._parsed) {
        return error;
    }

    const { response } = error;
    if (!response || !response.data || !response.status) {
        return {
            _parsed: true,
            status: 500,
            statusText: 'Error',
            data: {
                message: 'An error occured.'
            }
        }
    }

    response._parsed = true;

    // Make sure the full messaged gets displayed in console.
    if (response.data && response.data.message) {
        console.error(response.data.message);
    }

    // If server error, respond with generic response for user.
    if (response?.status >= 500) {
        return {
            ...response,
            data: {
                message: 'Oh no. Looks like our server did something wrong.',
            }
        }
    }

    // Return the short message to display to users.
    if (response.data && response.data._message) {
        response.data.message = response.data._message;
    }

    return response;
}