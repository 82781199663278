import { useState, useEffect } from 'react';
import { DyteMeeting } from '@dytesdk/react-ui-kit';
import { useDyteClient, DyteProvider, useDyteMeeting } from '@dytesdk/react-web-core';
import { getShowerMeetingDetails } from './services/dyte-services';
import { useAPICall } from './utils/useAPICall';
import html2canvas from 'html2canvas';

function App() {
  const [dyteMeeting, initMeeting] = useDyteClient();
  const searchParams = new URL(window.location.href).searchParams;
  const meeting_id = searchParams.get('id');

  const [{ isLoading, isError, response }, setAPICall] = useAPICall(getShowerMeetingDetails, { onLoad: false });
 
  const hideEndMeetingButton = () => {
    const button = document.querySelector('.end-meeting-all-btn'); // Adjust the selector as needed
    if (button) {
      button.style.display = 'none';
    }
  };

  useEffect(() => {
    setAPICall({
      payload: meeting_id
    });
  }, []);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }

    if (isError) {
      // Handle Error;
      return () => null;
    }

    if (response && response?.status === 200) {
      hideEndMeetingButton();
    }
  }, [response]);

  useEffect(() => {
    const authToken = searchParams.get('authToken');

    if (!authToken) {
      alert(
        "An authToken wasn't passed, please pass an authToken in the URL query to join a meeting."
      );
      return;
    }

    initMeeting({
      authToken,
    });
  }, []);

  return (
    <DyteProvider value={dyteMeeting}>
      {response?.status === 200 &&
        <div className="background-blur">
          <DyteMeetingComponent response={response} />
        </div>}
      <style jsx>{`
        .background-blur {
          position: relative;
          height: 100vh;
          width: 100vw;
        }
        .background-blur::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          ${response?.data?.data?.dyteMeetingDetails?.background_image ? `
            background-image: url(${response.data.data.dyteMeetingDetails.background_image});
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            filter: blur(5px);
            width: 100vw;
            height: 100vh ;
          ` : `
            background-color: #f9ebeb;
          `}
          z-index: -1;
        }
      `}</style>
    </DyteProvider>
  );
}

function DyteMeetingComponent(response) {
  const [userJoined, setUserJoined] = useState(false);
  const { meeting } = useDyteMeeting();
  useEffect(() => {
    if (meeting) {
      const onRoomJoin = () => {
        setUserJoined(true); // Set userJoined to true when the user joins the room
      };

      meeting.self.on('roomJoin', onRoomJoin);

      return () => {
        meeting.self.off('roomJoin', onRoomJoin);
      };
    }
  }, [meeting]);

  useEffect(() => {
    // Wait for the DyteMeeting component to be rendered
    const interval = setInterval(() => {
      const controlBarHeader = document.querySelector("#root > div > dyte-meeting")?.shadowRoot?.querySelector("dyte-header");
      const controlBar = document.querySelector("#root > div > dyte-meeting")?.shadowRoot?.querySelector("dyte-controlbar");
      if (controlBar) {
        controlBar.style.backgroundColor = 'transparent';
      }

      if (controlBarHeader) {
        controlBarHeader.style.backgroundColor = 'transparent';
      }

    }, 1000); 

    return () => clearInterval(interval); 
  }, [meeting]);

  const endMeetingForAll = () => {
    const dyteMeetingElement = document.querySelector("#root > div > dyte-meeting");
    const endMeetingButton = dyteMeetingElement?.shadowRoot
      .querySelector("dyte-dialog-manager")?.shadowRoot
      .querySelector("dyte-dialog > dyte-leave-meeting")?.shadowRoot
      .querySelector("div > div.content > dyte-button:nth-child(3)")?.shadowRoot
      .querySelector("button > span.content > slot")
      ?.assignedNodes()
      .find(node => node.textContent.trim() === "End meeting for all");

    if (endMeetingButton) {
      endMeetingButton.parentElement.style.display = 'none'; // Hide the button
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      endMeetingForAll();
    }, 100); // Check every second

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [meeting]);

  const changeTitleFontSize = () => {
    const titleElement = document.querySelector("#root > div > dyte-meeting")?.shadowRoot
      .querySelector("#header-center > dyte-meeting-title")?.shadowRoot
      .querySelector("dyte-tooltip");
    if (titleElement) {
        titleElement.style.fontSize = '22px'; // Change this value to the desired font size
    }
  };

  const changeLogoSize = () => {
    const logoImage = document.querySelector("#root > div > dyte-meeting")?.shadowRoot
      .querySelector("#header-left > dyte-logo")?.shadowRoot
      .querySelector("img");

    if (logoImage) {
      logoImage.style.width = '160px';
      logoImage.style.height = '90px';
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      changeLogoSize();
      changeTitleFontSize();
    }, 1000); // Check every second

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [meeting]);

  const changeButtonColors = () => {
    const dyteMeetingElement = document.querySelector("#root > div > dyte-meeting");
    const setupControlsMediaElement = document.querySelector("#root > div > dyte-meeting").shadowRoot
      .querySelector("dyte-setup-screen")?.shadowRoot
      .querySelector("#setupcontrols-media")
   
      const micToggleElement = setupControlsMediaElement?.querySelector("dyte-mic-toggle")?.shadowRoot.querySelector("dyte-controlbar-button");
      const micToggleTitle = setupControlsMediaElement?.querySelector("dyte-mic-toggle")?.getAttribute("title");
      const cameraToggleTitle = setupControlsMediaElement?.querySelector("dyte-camera-toggle")?.getAttribute("title");
      const isCameraOn = cameraToggleTitle === "Video On";
      const isMicOn = micToggleTitle === "Mic On";
    if (micToggleElement) {
      micToggleElement.style.borderRadius = '8px';
      micToggleElement.style.backgroundColor = `${response?.response?.data?.data?.dyteThemeDataDetails?.buttonBackground}`;
    }
    const cameraToggleElement = setupControlsMediaElement?.querySelector("dyte-camera-toggle")?.shadowRoot.querySelector("dyte-controlbar-button");
    if (cameraToggleElement) {
      micToggleElement.style.borderRadius = '8px';
      cameraToggleElement.style.backgroundColor = `${response?.response?.data?.data?.dyteThemeDataDetails?.buttonBackground}`;
    }

    const svgElement = setupControlsMediaElement?.querySelector("dyte-mic-toggle")?.shadowRoot.querySelector("dyte-tooltip > dyte-controlbar-button")?.shadowRoot.querySelector("#icon")?.shadowRoot.querySelector("div > svg")
    const svgElementMic = setupControlsMediaElement?.querySelector("dyte-camera-toggle")?.shadowRoot.querySelector("dyte-tooltip > dyte-controlbar-button")?.shadowRoot.querySelector("#icon")?.shadowRoot.querySelector("div > svg");
    
    if (isMicOn && svgElement) {
      svgElement.style.color = `${response?.response?.data?.data?.dyteThemeDataDetails?.buttonColor}`; // Set the color to red
    }

    if (isCameraOn && svgElementMic) {
      svgElementMic.style.color = `${response?.response?.data?.data?.dyteThemeDataDetails?.buttonColor}`; // Set the color to red
    }
    if (!dyteMeetingElement) return;
  
    const buttonSelectors = [
      "#controlbar-center > dyte-mic-toggle",
      "#controlbar-center > dyte-camera-toggle",
      "#controlbar-center > dyte-stage-toggle",
      "#controlbar-center > dyte-more-toggle",
      "#controlbar-center > dyte-leave-button",
      "#controlbar-left > dyte-fullscreen-toggle",
      "#controlbar-left > dyte-settings-toggle",
      "#controlbar-left > dyte-screen-share-toggle",
      "#controlbar-right > dyte-chat-toggle",
      "#controlbar-right > dyte-participants-toggle",
      "dyte-setup-screen #setupcontrols-media > dyte-mic-toggle"
    ];

    buttonSelectors.forEach(selector => {
      const buttonElement = dyteMeetingElement.shadowRoot.querySelector(selector)?.shadowRoot.querySelector("dyte-controlbar-button");
      if (buttonElement) {
        buttonElement.style.backgroundColor = `${response?.response?.data?.data?.dyteThemeDataDetails?.buttonBackground}`;
      }
    });

    // const customButton = document.createElement('button');
    // customButton.setAttribute('aria-label', 'Copy Link Button');
    // customButton.setAttribute('part', 'button');
  
    // const icon = document.createElement('img');
    // icon.src = 'https://img.icons8.com/ios-filled/50/000000/copy-link.png'; // Copy Link icon URL
    // icon.alt = 'Copy Link Icon';
    // icon.style.width = '24px'; // Adjust the size of the icon
    // icon.style.height = '24px';
    // icon.style.marginBottom = '4px'; // Add some space between the icon and the label
  
    // const label = document.createElement('span');
    // label.classList.add('label');
    // label.setAttribute('part', 'label');
    // label.innerText = 'Copy Link';
    // label.style.color = 'rgb(255, 255, 255)'; // Adjust the color if needed
  
    // customButton.style.position = 'absolute';
    // customButton.style.right = '45px';
    // customButton.style.display = 'flex';
    // customButton.style.flexDirection = 'column';
    // customButton.style.alignItems = 'center';
    // customButton.style.justifyContent = 'center';
    // customButton.style.height = '64px'; 
    // customButton.style.width = '64px'; // Set the width
    // customButton.style.backgroundColor = '#f77979'; // Change background color
    // customButton.style.color = 'white';
    // customButton.style.border = 'none';
    // customButton.style.borderRadius = '8px'; // Adjust border radius
    // customButton.style.cursor = 'pointer';
    // customButton.style.marginLeft = '8px'; // Add some space between buttons
  
    // // Event listener for the button click to copy the URL to clipboard
    // customButton.onclick = () => {
    //   navigator.clipboard.writeText(window.location.href).then(() => {
    //     alert('URL copied to clipboard!');
    //   }).catch(err => {
    //     console.error('Failed to copy: ', err);
    //   });
    // };
  
    // // Append the icon and label to the button
    // customButton.appendChild(icon);
    // customButton.appendChild(label);
  
  
    // // Media query styles for responsiveness
    // const styleSheet = document.createElement("style");
    // styleSheet.type = "text/css";
    // styleSheet.innerText = `
    //   @media (min-width: 1366px) {
    //     button[part="button"] {
    //       right: -45px;
    //       padding: 6px 24px;
    //     }
    //   }
    //   @media (max-width: 768px) {
    //     button[part="button"] {
    //       right: 20px;
    //       padding: 10px 20px;
    //       font-size: 14px;
    //     }
    //   }
    //   @media (max-width: 480px) {
    //     button[part="button"] {
    //       right: 10px;
    //       padding: 8px 16px;
    //       font-size: 12px;
    //     }
    //   }
    // `;
    // // Event listener for the button click
    // customButton.onclick = () => {
    //   navigator.clipboard.writeText(window.location.href).then(() => {
    //     console.error('copy link');
    //   }).catch(err => {
    //     console.error('Failed to copy: ', err);
    //   });
    // };

    // const controlbarCenter = dyteMeetingElement?.shadowRoot?.querySelector("#controlbar-center");
    // if (controlbarCenter) {
    //   controlbarCenter?.appendChild(customButton);
    // }
  };
  
  useEffect(() => {
    const interval = setInterval(() => {
      // Check if all buttons have been changed
      changeButtonColors();
      const dyteMeetingElement = document.querySelector("#root > div > dyte-meeting");
      const buttonSelectors = [
        "#controlbar-center > dyte-mic-toggle",
        "#controlbar-center > dyte-camera-toggle",
        "#controlbar-center > dyte-stage-toggle",
        "#controlbar-center > dyte-more-toggle",
        "#controlbar-center > dyte-leave-button",
        "#controlbar-left > dyte-fullscreen-toggle",
        "#controlbar-left > dyte-settings-toggle",
        "#controlbar-left > dyte-screen-share-toggle",
        "#controlbar-right > dyte-chat-toggle",
        "#controlbar-right > dyte-participants-toggle",
        "dyte-setup-screen #setupcontrols-media > dyte-mic-toggle"
      ];

      buttonSelectors.forEach(selector => {
        const buttonElement = dyteMeetingElement.shadowRoot.querySelector(selector)?.shadowRoot.querySelector("dyte-controlbar-button");
        const buttonSvgElement = buttonElement?.shadowRoot.querySelector("#icon")?.shadowRoot.querySelector("div > svg");
        const buttonSpanElement = buttonElement?.shadowRoot.querySelector("button > span");
        if (buttonSpanElement) {
            buttonSpanElement.style.color = `${response?.response?.data?.data?.dyteThemeDataDetails?.buttonColor}`;
        }

          // Check for mic and camera toggles
      const micToggle = dyteMeetingElement.shadowRoot.querySelector("#controlbar-center > dyte-mic-toggle");
      const cameraToggle = dyteMeetingElement.shadowRoot.querySelector("#controlbar-center > dyte-camera-toggle");
    

        const micTitle = micToggle?.getAttribute("title");
        const cameraTitle = cameraToggle?.getAttribute("title");
        
        const micIsOn = micTitle === "Mic On";
        const cameraIsOn = cameraTitle === "Video On";
        if (buttonSvgElement) {
          if ((selector.includes("dyte-mic-toggle") && micIsOn) || (selector.includes("dyte-camera-toggle") && cameraIsOn)) {
            buttonSvgElement.style.color = `${response?.response?.data?.data?.dyteThemeDataDetails?.buttonColor}`;
          }else if ((!selector.includes("dyte-mic-toggle")) && (!selector.includes("dyte-camera-toggle"))) {
            buttonSvgElement.style.color = `${response?.response?.data?.data?.dyteThemeDataDetails?.buttonColor}`;
          }
        }
    
  });
  
      const allButtonsChanged = buttonSelectors.every(selector => {
        const buttonElement = dyteMeetingElement.shadowRoot.querySelector(selector)?.shadowRoot.querySelector("dyte-controlbar-button");
        return buttonElement && buttonElement.style.backgroundColor === `${response?.response?.data?.data?.dyteThemeDataDetails?.buttonBackground}`;
      });
  
      if (allButtonsChanged) {
        clearInterval(interval);
      }
    }, 100); // Check every second
  
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [meeting]);

  return (
    <>
      <DyteMeeting style={{ backgroundColor: 'transparent' }} meeting={meeting} mode='fill' />
      {/* { ( 
        <button onClick={captureScreenshot} className="screenshot-btn">Capture Screenshot</button>
      )} */}
      <style jsx>{`
        .screenshot-btn {
          position: absolute;
          top: 10px;
          right: 10px;
          padding: 10px 20px;
          background-color: #007bff;
          color: #fff;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          z-index: 10;
        }
        .screenshot-btn:hover {
          background-color: #0056b3;
        }
      `}</style>
    </>
  );
}

export default App;
